/* eslint-disable vue/max-len */
import * as Vue from 'vue';
import { computed, ref, type Ref, type ShallowRef, shallowRef, watch, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import syncedStore, { boxed, enableVueBindings, filterArray, getYjsDoc } from '@syncedstore/core';
import { v4 } from 'uuid';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import {
  type DoubleMaterialityIroImpactType,
  DoubleMaterialityIroRiskAndOpportunityType,
  DoubleMaterialityMandatoryReason,
  DoubleMaterialitySurveyStatus,
  DoubleMaterialitySurveyType,
  type PgDoubleMaterialitySelectionQuery,
  type PgDoubleMaterialitySelectionQueryVariables,
} from '@/__generated__/types';
import STORE_QUERY from './store.query';
import type {
  IroImpact,
  IroRiskAndOpportunity,
  PotentialMagnitude,
  RecommendedESRS,
  ResultCalculation,
  Stakeholder,
  StakeholderEngageOption,
  StakeholderOption,
  Survey,
  SurveySettings,
  TimeHorizons,
  Topic,
  TopicsOptions,
} from './types';
import { calculateMaterialityForImpact, calculateMaterialityForRiskAndOpportunity, STAKEHOLDER_ENGAGE_SURVEY_NAME } from './utils';

enableVueBindings(Vue);

export type Store = ReturnType<typeof initializeStore>;

const store = shallowRef<Store>();
const provider = shallowRef<TiptapCollabProvider>();
const doubleMaterialityId = ref<string>();

const isReady = ref(false);

const isNeededDataLoaded = ref(false);
const defaultTopics = shallowRef<PgDoubleMaterialitySelectionQuery['getDoubleMaterialityTopics']>([]);
const dataPointCategories = shallowRef<PgDoubleMaterialitySelectionQuery['getDataPointCategoriesForDoubleMateriality']>([]);

function initializeStore() {
  return syncedStore({
    potentialMagnitude: {} as PotentialMagnitude,
    timeHorizons: {} as TimeHorizons,
    topics: [] as Topic[],
    topicsOptions: {} as TopicsOptions,
    iroImpacts: [] as IroImpact[],
    iroRisksAndOpportunities: [] as IroRiskAndOpportunity[],
    stakeholders: [] as Stakeholder[],
    stakeholderOptions: [] as StakeholderOption[],
    stakeholderEngageOptions: [] as StakeholderEngageOption[],
    surveys: [] as Survey[],
    surveySettings: {} as SurveySettings,
    resultCalculation: {} as ResultCalculation,
    recommendedESRS: {} as RecommendedESRS,
  });
}

export function useCreateStore(id: Ref<string>, storeOnly = false) {
  watchEffect((onCleanup) => {
    if (id.value) {
      doubleMaterialityId.value = id.value;

      store.value = initializeStore();

      provider.value = new TiptapCollabProvider({
        name: `doubleMateriality.${id.value}`,
        appId: 'xy9dj9e6',
        token: 'notoken',
        document: getYjsDoc(store.value),
        onAuthenticated() {
          isReady.value = true;
        },
      });

      onCleanup(() => {
        provider.value?.disconnect();
        provider.value?.destroy();
        store.value = undefined;
        provider.value = undefined;
        doubleMaterialityId.value = undefined;
      });
    }
  });

  if (storeOnly) {
    return;
  }

  const { result, loading } = useQuery<PgDoubleMaterialitySelectionQuery, PgDoubleMaterialitySelectionQueryVariables>(STORE_QUERY);

  watch(result, (newResultValue) => {
    if (newResultValue) {
      defaultTopics.value = newResultValue.getDoubleMaterialityTopics;
      dataPointCategories.value = newResultValue.getDataPointCategoriesForDoubleMateriality;
    }
  }, { immediate: true });
  watch(loading, (newLoadingValue) => {
    isNeededDataLoaded.value = !newLoadingValue;
  }, { immediate: true });
}

export function useStore() {
  if (!store.value) {
    throw new Error('Store is not initialized!');
  }
  return store as ShallowRef<Store>;
}

export function useDoubleMaterialityId() {
  return doubleMaterialityId;
}

export function useIsStoreReady(storeOnly = false) {
  return computed(() => isReady.value && (storeOnly ? true : isNeededDataLoaded.value));
}

export function useDefaultTopics() {
  return defaultTopics;
}

function generateId(type: 'topic' | 'iroImpact' | 'iroRiskAndOpportunity' | 'stakeholder' | 'survey') {
  const newId = v4();

  switch (type) {
    case 'topic': {
      if (!(store.value?.topics.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    case 'iroImpact': {
      if (!(store.value?.iroImpacts.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    case 'iroRiskAndOpportunity': {
      if (!(store.value?.iroRisksAndOpportunities.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    case 'stakeholder': {
      if (!(store.value?.stakeholders.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    case 'survey': {
      if (!(store.value?.surveys.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    default:
      throw new Error(`Invalid type ${type}`);
  }

  return generateId(type);
}

function addInitialPotentialMagnitude() {
  if (store.value) {
    store.value.potentialMagnitude.criticallyRelevantFinancialEffectValue = 0;
    store.value.potentialMagnitude.criticallyRelevantFinancialEffectComment = '';
    store.value.potentialMagnitude.criticallyRelevantFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.significantFinancialEffectValue = 0;
    store.value.potentialMagnitude.significantFinancialEffectComment = '';
    store.value.potentialMagnitude.significantFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.lowFinancialEffectValue = 0;
    store.value.potentialMagnitude.lowFinancialEffectComment = '';
    store.value.potentialMagnitude.lowFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.minorFinancialEffectValue = 0;
    store.value.potentialMagnitude.minorFinancialEffectComment = '';
    store.value.potentialMagnitude.minorFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.almostNoFinancialEffectValue = 0;
    store.value.potentialMagnitude.almostNoFinancialEffectComment = '';
    store.value.potentialMagnitude.almostNoFinancialEffectDocument = undefined;
  }
}

function addInitialTimeHorizons() {
  if (store.value) {
    store.value.timeHorizons.shortTermValue = 0;
    store.value.timeHorizons.shortTermComment = '';
    store.value.timeHorizons.shortTermDocument = undefined;

    store.value.timeHorizons.mediumTermValue = 5;
    store.value.timeHorizons.mediumTermComment = '';
    store.value.timeHorizons.mediumTermDocument = undefined;

    store.value.timeHorizons.longTermValue = 5;
    store.value.timeHorizons.longTermComment = '';
    store.value.timeHorizons.longTermDocument = undefined;
  }
}

function addInitialData() {
  if (store.value) {
    store.value!.iroImpacts.splice(0, store.value.iroImpacts.length);
    store.value!.iroRisksAndOpportunities.splice(0, store.value.iroRisksAndOpportunities.length);

    defaultTopics.value.forEach((defaultTopic) => {
      const topicId = generateId('topic');

      store.value!.topics.push({
        _id: topicId,
        defaultTopicId: defaultTopic._id,
        esrs: defaultTopic.esrs,
        topic: defaultTopic.topic,
        subTopic: '',
        subSubTopic: '',
        timeHorizon: '',
        valueChain: [],
        comment: '',
        document: boxed(null),
      });

      store.value!.topicsOptions[defaultTopic.topic] = defaultTopic.subTopics.map((item) => ({
        subTopic: item.subTopic,
        subSubTopics: [...item.subSubTopics],
      }));

      store.value!.iroImpacts.push({
        _id: generateId('iroImpact'),
        topicId,
        impact: '',
        type: null,
        comment: '',
        document: boxed(null),
        scale: null,
        scope: null,
        irremediability: null,
        likelihood: null,
        scoreComment: '',
        scoreDocument: boxed(null),
      });

      store.value!.iroRisksAndOpportunities.push({
        _id: generateId('iroRiskAndOpportunity'),
        topicId,
        riskAndOpportunity: '',
        type: null,
        comment: '',
        document: boxed(null),
        likelihood: null,
        potentialMagnitude: null,
        scoreComment: '',
        scoreDocument: boxed(null),
      });
    });

    dataPointCategories.value.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        store.value!.recommendedESRS[`${category._id}_${subcategory._id}`] = {
          value: !category.doubleMaterialityTopic
            || subcategory.doubleMaterialityMandatoryReason === DoubleMaterialityMandatoryReason.Regardless,
          materiality: null,
        };
      });
    });

    addInitialResultCalculation();

    calculateRecommendedESRS();
  }
}

function addTopic(index: number) {
  if (store.value) {
    const topicId = generateId('topic');

    store.value.topics.splice(index + 1, 0, {
      _id: topicId,
      defaultTopicId: store.value.topics[index].defaultTopicId,
      esrs: store.value.topics[index].esrs,
      topic: store.value.topics[index].topic,
      subTopic: '',
      subSubTopic: '',
      timeHorizon: '',
      valueChain: [],
      comment: '',
      document: boxed(null),
    });

    store.value.iroImpacts.push({
      _id: generateId('iroImpact'),
      topicId,
      impact: '',
      type: null,
      comment: '',
      document: boxed(null),
      scale: null,
      scope: null,
      irremediability: null,
      likelihood: null,
      scoreComment: '',
      scoreDocument: boxed(null),
    });

    store.value.iroRisksAndOpportunities.push({
      _id: generateId('iroRiskAndOpportunity'),
      topicId,
      riskAndOpportunity: '',
      type: null,
      comment: '',
      document: boxed(null),
      likelihood: null,
      potentialMagnitude: null,
      scoreComment: '',
      scoreDocument: boxed(null),
    });

    calculateRecommendedESRS();
  }
}

function changeTopic(index: number, topic: string) {
  if (store.value) {
    store.value.topics[index].topic = topic;
    store.value.topics[index].esrs = defaultTopics.value.find((item) => item.topic === topic)?.esrs ?? '';
    store.value.topics[index].subTopic = '';
    store.value.topics[index].subSubTopic = '';

    calculateRecommendedESRS();
  }
}

function removeTopic(index: number) {
  if (store.value) {
    const topicId = store.value.topics[index]._id;
    store.value.topics.splice(index, 1);
    filterArray(store.value.iroImpacts, (iroImpact) => iroImpact.topicId !== topicId);
    filterArray(
      store.value.iroRisksAndOpportunities,
      (iroRiskAndOpportunity) => iroRiskAndOpportunity.topicId !== topicId,
    );

    calculateRecommendedESRS();
  }
}

function changeSubTopic(index: number, subTopic: string) {
  if (store.value) {
    store.value.topics[index].subTopic = subTopic;
    store.value.topics[index].subSubTopic = '';

    calculateRecommendedESRS();
  }
}

function createSubTopic(index: number, subTopic: string) {
  store.value?.topicsOptions[store.value.topics[index].topic]?.push({
    subTopic,
    subSubTopics: [],
  });

  calculateRecommendedESRS();
}

function createSubSubTopic(index: number, subSubTopic: string) {
  store
    .value
    ?.topicsOptions[store.value.topics[index].topic]
    ?.find((item) => item.subTopic === store.value?.topics[index].subTopic)
    ?.subSubTopics
    .push(subSubTopic);

  calculateRecommendedESRS();
}

function addIroImpact(index: number, topicId: string) {
  if (store.value) {
    store.value.iroImpacts.splice(index + 1, 0, {
      _id: generateId('iroImpact'),
      topicId,
      impact: '',
      type: null,
      comment: '',
      document: boxed(null),
      scale: null,
      scope: null,
      irremediability: null,
      likelihood: null,
      scoreComment: '',
      scoreDocument: boxed(null),
    });

    calculateRecommendedESRS();
  }
}

function removeIroImpact(index: number) {
  if (store.value) {
    // Don't remove the last IRO impact for given topic.
    const { topicId } = store.value.iroImpacts[index];

    if (store.value.iroImpacts.filter((iroImpact) => iroImpact.topicId === topicId).length > 1) {
      const impactId = store.value.iroImpacts[index]._id;

      store.value.iroImpacts.splice(index, 1);

      removeAnswersFromSurveys(impactId);
    }

    calculateRecommendedESRS();
  }
}

function changeIroImpact(index: number, iroImpact: string) {
  if (store.value) {
    store.value.iroImpacts[index].impact = iroImpact.trim();
    if (!store.value.iroImpacts[index].impact) {
      changeIroImpactType(index, null);
    }

    removeAnswersFromSurveys(store.value.iroImpacts[index]._id);

    calculateRecommendedESRS();
  }
}

function changeIroImpactType(index: number, type: DoubleMaterialityIroImpactType | null) {
  if (store.value) {
    store.value.iroImpacts[index].type = type;
    store.value.iroImpacts[index].scale = null;
    store.value.iroImpacts[index].scope = null;
    store.value.iroImpacts[index].irremediability = null;
    store.value.iroImpacts[index].likelihood = null;

    removeAnswersFromSurveys(store.value.iroImpacts[index]._id);

    calculateRecommendedESRS();
  }
}

function addIroRiskAndOpportunity(index: number, topicId: string) {
  if (store.value) {
    store.value.iroRisksAndOpportunities.splice(index + 1, 0, {
      _id: generateId('iroRiskAndOpportunity'),
      topicId,
      riskAndOpportunity: '',
      type: null,
      comment: '',
      document: boxed(null),
      likelihood: null,
      potentialMagnitude: null,
      scoreComment: '',
      scoreDocument: boxed(null),
    });

    calculateRecommendedESRS();
  }
}

function removeIroRiskAndOpportunity(index: number) {
  if (store.value) {
    // Don't remove the last IRO risk and opportunity for given topic.
    const { topicId } = store.value.iroRisksAndOpportunities[index];

    if (store.value.iroRisksAndOpportunities.filter((iroRiskAndOpportunity) => iroRiskAndOpportunity.topicId === topicId).length > 1) {
      const riskAndOpportunityId = store.value.iroRisksAndOpportunities[index]._id;

      store.value.iroRisksAndOpportunities.splice(index, 1);

      removeAnswersFromSurveys(riskAndOpportunityId);
    }

    calculateRecommendedESRS();
  }
}

function changeIroRiskAndOpportunityRiskAndOpportunity(index: number, text: string) {
  if (store.value) {
    store.value.iroRisksAndOpportunities[index].riskAndOpportunity = text.trim();
    if (!store.value.iroRisksAndOpportunities[index].riskAndOpportunity) {
      changeIroRiskAndOpportunityType(index, null);
    }

    removeAnswersFromSurveys(store.value.iroRisksAndOpportunities[index]._id);

    calculateRecommendedESRS();
  }
}

function changeIroRiskAndOpportunityType(index: number, type: DoubleMaterialityIroRiskAndOpportunityType | null) {
  if (store.value) {
    store.value.iroRisksAndOpportunities[index].type = type;
    store.value.iroRisksAndOpportunities[index].potentialMagnitude = null;
    store.value.iroRisksAndOpportunities[index].likelihood = null;

    removeAnswersFromSurveys(store.value.iroRisksAndOpportunities[index]._id);

    calculateRecommendedESRS();
  }
}

function addDefaultStakeholderOptions() {
  [
    'Employees',
    'Customers',
    'Suppliers and Vendors',
    'Shareholders and owners',
    'Board of directors',
    'Local communities',
    'Environmental advocacy groups',
    'NGOs',
    'Unions and labor organization',
    'Local authorities and municipalities',
    'Government and regulatory authorities',
    'Industry regulators',
    'Foundations and philanthropic organizations',
    'Civil society',
    'Creditors',
    'Debt holders',
    'Competitors',
    'Industry associations and trade groups',
    'Nature',
    'Media and press',
    'Insurance companies',
    'Shareholder advocacy groups',
    'Retired employees',
    'Families of employees',
    'Legal advisors and law firms',
    'Educational institutions',
    'Auditors and accounting firms',
    'Investment analysts',
    'Investment funds and institutional investors',
    'Asset managers',
    'Startups and incubators',
    'Strategic partners and alliances',
    'Professional associations',
  ].forEach((option) => {
    store.value?.stakeholderOptions.push(option);
  });
}

function addDefaultStakeholderEngageOptions() {
  [
    STAKEHOLDER_ENGAGE_SURVEY_NAME,
    'Interviews',
    'Focus groups',
    'Workshops',
    'Suggestion boxes',
    'Company wide meetings with open forums',
    'Webinars',
    'Other',
  ].forEach((option) => {
    store.value?.stakeholderEngageOptions.push(option);
  });
}

function addInitialStakeholders() {
  for (let i = 0; i < 3; i += 1) {
    addStakeholder();
  }
}

function addStakeholder() {
  if (store.value) {
    store.value.stakeholders.push({
      _id: generateId('stakeholder'),
      stakeholder: null,
      description: '',
      reasons: '',
      engage: null,
    });
  }
}

function removeStakeholder(index: number) {
  if (store.value) {
    store.value.stakeholders.splice(index, 1);
  }
}

function createStakeholderOption(option: string) {
  if (store.value) {
    store.value.stakeholderOptions.push(option);
  }
}

function createStakeholderEngageOption(option: string) {
  if (store.value) {
    store.value.stakeholderEngageOptions.push(option);
  }
}

function changeStakeholderEngage(index: number, value: string | null) {
  if (store.value) {
    const stakeholder = store.value.stakeholders[index];
    if (stakeholder) {
      if (stakeholder.engage === STAKEHOLDER_ENGAGE_SURVEY_NAME && value !== STAKEHOLDER_ENGAGE_SURVEY_NAME) {
        store.value.surveys.forEach((survey) => {
          if (survey.stakeholderId === stakeholder._id) {
            survey.stakeholderId = null;
          }
        });
      }
      stakeholder.engage = value;
    }
  }
}

function addInitialSurveySettings(organizationName: string) {
  if (store.value) {
    store.value.surveySettings.impactsSurveyTemplate = {
      subject: {
        EN: 'Subject: Invitation to Participate in Double Materiality Assessment (DMA)',
        DE: 'Betreff: Einladung zur Teilnahme an der doppelten Wesentlichkeitsanalyse (DWA)',
      },
      body: {
        EN: `[company name] values your perspectives and opinions as an important stakeholder in our pursuit of greater sustainability and responsible business practices. As part of our double materiality analysis (context: CSRD), we have assessed impacts related to our sustainability that may significantly affect [company name]'s business and certain stakeholders.

We would like to ask you to rate the following questions according to their significance for [company name]. Please evaluate these issues in the context of [company name], not in the context of a global view. 

With your rating, you are making a direct contribution to the sustainable management of [company name].

Thank you for your time and support!`.replaceAll('[company name]', organizationName),
        DE: `[company name] schätzt Ihre Perspektiven und Meinungen als wichtige Interessens- bzw. Anspruchsgruppe in unserem kontinuierlichen Streben nach mehr Nachhaltigkeit und verantwortungsbewussten Geschäftspraktiken. Im Rahmen unserer Analyse zur doppelten Wesentlichkeit (Kontext: CSRD) haben wir Einflüsse und Auswirkungen („Impacts“) im Zusammenhang mit unserer Nachhaltigkeit bewertet, welche die Geschäftstätigkeit von [company name] und gewisse Anspruchsgruppen erheblich beeinflussen können.

Ihre Meinung ist ein wesentlicher Bestandteil dieses Prozesses. Wir möchten Sie bitten, die folgenden Fragen nach ihrer Bedeutung für [company name] zu bewerten. Bitte bewerten Sie diese Fragen im Kontext von [company name], nicht im Kontext einer globalen Betrachtung. Ihre Sichtweise wird uns helfen, unsere eigene Bewertung dieser Faktoren zu verfeinern und zu beurteilen, welche Nachhaltigkeitsaspekte für unser Geschäft wesentlich sind.

Mit Ihrer Bewertung leisten Sie einen direkten Beitrag zum nachhaltigen Management von [company name] und zur Stärkung unserer Wirtschaft.

Wir bedanken uns für Ihre Zeit und Unterstützung!`.replaceAll('[company name]', organizationName),
      },
    };

    store.value.surveySettings.risksAndOpportunitiesSurveyTemplate = {
      subject: {
        EN: 'Subject: Invitation to Participate in Double Materiality Assessment (DMA)',
        DE: 'Betreff: Einladung zur Teilnahme an der doppelten Wesentlichkeitsanalyse (DWA)',
      },
      body: {
        EN: `[company name] values your perspectives and opinions as an important stakeholder in our pursuit of greater sustainability and responsible business practices. As part of our double materiality analysis (context: CSRD), we have assessed risks and opportunities related to our sustainability that may significantly affect [company name]'s business and certain stakeholders.

We would like to ask you to rate the following questions according to their significance for [company name]. Please evaluate these issues in the context of [company name], not in the context of a global view. 

With your rating, you are making a direct contribution to the sustainable management of [company name].

Thank you for your time and support!`.replaceAll('[company name]', organizationName),
        DE: `[company name] schätzt Ihre Perspektiven und Meinungen als wichtige Stakeholder in unserem kontinuierlichen Streben nach mehr Nachhaltigkeit und verantwortungsvollen Geschäftspraktiken. Im Rahmen unserer doppelten Wesentlichkeitsanalyse (Kontext: CSRD) haben wir finanzielle Risiken und Chancen im Zusammenhang mit unserer Nachhaltigkeit bewertet, die das Geschäft von [company name] und bestimmte Stakeholder jetzt oder in Zukunft erheblich beeinträchtigen könnten.

IIhre Meinung ist ein wesentlicher Bestandteil dieses Prozesses. Wir möchten Sie bitten, die folgenden Fragen nach ihrer Bedeutung für [company name] zu bewerten. Bitte bewerten Sie diese Fragen im Kontext von [company name], nicht im Kontext einer globalen Betrachtung. Ihre Sichtweise wird uns helfen, unsere eigene Bewertung dieser Faktoren zu verfeinern und zu beurteilen, welche Nachhaltigkeitsaspekte für unser Geschäft wesentlich sind.

Mit Ihrer Bewertung leisten Sie einen direkten Beitrag zum nachhaltigen Management von [company name] und zur Stärkung unserer Wirtschaft.

Wir danken Ihnen für Ihre Zeit und Ihre Unterstützung!`.replaceAll('[company name]', organizationName),
      },
    };
  }
}

function addSurvey() {
  if (store.value) {
    store.value.surveys.push({
      _id: generateId('survey'),
      stakeholderId: null,
      fullName: '',
      email: '',
      type: null,
      status: null,
      explanation: '',
      answers: {},
    });
  }
}

function changeSurvey(index: number, field: 'stakeholderId', value: string | null): void;
function changeSurvey(index: number, field: 'fullName', value: string): void;
function changeSurvey(index: number, field: 'email', value: string): void;
function changeSurvey(index: number, field: 'type', value: DoubleMaterialitySurveyType | null): void;
function changeSurvey(
  index: number,
  field: 'stakeholderId' | 'fullName' | 'email' | 'type',
  value: DoubleMaterialitySurveyType | string | null,
) {
  if (store.value) {
    const survey = store.value.surveys[index];

    switch (field) {
      case 'email':
        survey.email = value ?? '';
        break;
      case 'fullName':
        survey.fullName = value ?? '';
        break;
      case 'stakeholderId':
        survey.stakeholderId = value;
        break;
      case 'type':
        survey.type = value as (DoubleMaterialitySurveyType | null);
        break;
      default:
        throw new Error('Invalid type!');
    }

    if (survey.stakeholderId && survey.email && survey.fullName && survey.type) {
      survey.status = DoubleMaterialitySurveyStatus.NOT_SENT;
    } else {
      survey.status = null;
    }

    // Reset all answers.
    survey.answers = {};
  }
}

function removeSurvey(index: number) {
  if (store.value) {
    // Don't remove the lastSurvey.
    if (store.value.surveys.length > 1) {
      store.value.surveys.splice(index, 1);
    }
  }
}

function removeAnswersFromSurveys(id: string) {
  if (store.value) {
    store.value.surveys.forEach((survey) => {
      if (survey.answers[id]) {
        delete survey.answers[id];
      }
    });
  }
}

function addInitialResultCalculation() {
  if (store.value) {
    store.value.resultCalculation.internal = 0.8;
    store.value.resultCalculation.stakeholders = 0.2;
  }
}

function resetStore() {
  if (store.value) {
    (Object.keys(store.value.potentialMagnitude) as Array<keyof PotentialMagnitude>).forEach((key) => {
      delete store.value!.potentialMagnitude[key];
    });
    (Object.keys(store.value.timeHorizons) as Array<keyof TimeHorizons>).forEach((key) => {
      delete store.value!.timeHorizons[key];
    });
    store.value.topics.splice(0, store.value.topics.length);
    (Object.keys(store.value.topicsOptions) as Array<keyof TopicsOptions>).forEach((key) => {
      delete store.value!.topicsOptions[key];
    });
    store.value.iroImpacts.splice(0, store.value.iroImpacts.length);
    store.value.iroRisksAndOpportunities.splice(0, store.value.iroRisksAndOpportunities.length);
    store.value.stakeholders.splice(0, store.value.stakeholders.length);
    store.value.stakeholderOptions.splice(0, store.value.stakeholderOptions.length);
    store.value.stakeholderEngageOptions.splice(0, store.value.stakeholderEngageOptions.length);
    (Object.keys(store.value.recommendedESRS) as Array<keyof RecommendedESRS>).forEach((key) => {
      delete store.value!.recommendedESRS[key];
    });
    store.value.surveys.splice(0, store.value.surveys.length);
    (Object.keys(store.value.surveySettings) as Array<keyof SurveySettings>).forEach((key) => {
      delete store.value!.surveySettings[key];
    });

    addInitialResultCalculation();

    calculateRecommendedESRS();
  }
}

function calculateRecommendedESRS() {
  if (store.value && Object.keys(store.value.recommendedESRS ?? {}).length > 0) {
    const topicCategoryMap = dataPointCategories.value.reduce<Record<string, string>>((acc, item) => {
      if (item.doubleMaterialityTopic) {
        acc[item.doubleMaterialityTopic._id] = item._id;
      }

      return acc;
    }, {});
    const topicDefaultTopicMap = store.value.topics.reduce<Record<string, string>>((acc, topic) => {
      acc[topic._id] = topic.defaultTopicId;
      return acc;
    }, {});

    const impactsPerCategory = groupBy(store.value.iroImpacts, (iroImpact) => topicCategoryMap[topicDefaultTopicMap[iroImpact.topicId]]);
    const risksAndOpportunitiesPerCategory = groupBy(
      store.value.iroRisksAndOpportunities,
      (iroRisksAndOpportunities) => topicCategoryMap[topicDefaultTopicMap[iroRisksAndOpportunities.topicId]],
    );

    const impactsMaterialityPerCategory = mapValues(
      impactsPerCategory,
      (impacts) => impacts.reduce((acc, iroImpact) => {
        return acc || !!calculateMaterialityForImpact(
          iroImpact.scale,
          iroImpact.scope,
          iroImpact.irremediability,
          iroImpact.likelihood,
          iroImpact.type,
        );
      }, false),
    );
    const risksAndOpportunitiesMaterialityPerCategory = mapValues(
      risksAndOpportunitiesPerCategory,
      (risksAndOpportunities) => risksAndOpportunities.reduce((acc, iroRiskAndOpportunity) => {
        return acc || !!calculateMaterialityForRiskAndOpportunity(
          iroRiskAndOpportunity.likelihood,
          iroRiskAndOpportunity.potentialMagnitude,
        );
      }, false),
    );

    dataPointCategories.value.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        let newValue: boolean;

        switch (subcategory.doubleMaterialityMandatoryReason) {
          case DoubleMaterialityMandatoryReason.Regardless:
            newValue = true;
            break;
          case DoubleMaterialityMandatoryReason.TopicIsMaterial:
          case DoubleMaterialityMandatoryReason.DueToSustainabilityMatter:
            newValue = !!impactsMaterialityPerCategory[category._id] || !!risksAndOpportunitiesMaterialityPerCategory[category._id];
            break;
          case DoubleMaterialityMandatoryReason.DueToFinancialMateriality:
            newValue = !!risksAndOpportunitiesMaterialityPerCategory[category._id];
            break;
          default:
            newValue = store.value!.recommendedESRS[`${category._id}_${subcategory._id}`]!.value;
        }

        store.value!.recommendedESRS[`${category._id}_${subcategory._id}`] = {
          value: newValue,
          materiality: newValue,
        };
      });
    });
  }
}

export function useStoreMethods() {
  return {
    addInitialPotentialMagnitude,
    addInitialTimeHorizons,
    addInitialData,
    addTopic,
    changeTopic,
    removeTopic,
    changeSubTopic,
    createSubTopic,
    createSubSubTopic,
    addIroImpact,
    removeIroImpact,
    changeIroImpact,
    changeIroImpactType,
    addIroRiskAndOpportunity,
    removeIroRiskAndOpportunity,
    changeIroRiskAndOpportunityRiskAndOpportunity,
    changeIroRiskAndOpportunityType,
    addDefaultStakeholderOptions,
    addDefaultStakeholderEngageOptions,
    addInitialStakeholders,
    addStakeholder,
    removeStakeholder,
    createStakeholderOption,
    createStakeholderEngageOption,
    changeStakeholderEngage,
    addInitialSurveySettings,
    addSurvey,
    changeSurvey,
    removeSurvey,
    resetStore,
    addInitialResultCalculation,
    calculateRecommendedESRS,
  };
}
